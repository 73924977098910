import React from 'react';
import ShitSvg from './ShitSvg';
import Button from './UsButton';

export default function MainContent() {
    return(
        <main>
        <ShitSvg />
        <Button msg='Click me'/>
        </main>
    )
}