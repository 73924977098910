import React from 'react';
const ShitSvg = () => {
  return(
<svg style={{height: '40vh', width: 'auto', display: 'block', margin: 'auto' }} xmlns="http://www.w3.org/2000/svg" version="1.0" width="1099.000000pt" height="1280.000000pt" viewBox="0 0 1099.000000 1280.000000" >
 <defs>
    <linearGradient id="grad" gradientTransform="rotate(90)">
      <stop offset="5%"  stopColor="#ffb3f8" />
      <stop offset="95%" stopColor="brown" />
    </linearGradient>

  </defs>
<g transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)" fill="url(#grad)" stroke="none">
<path d="M3582 11578 c-19 -19 -15 -32 45 -140 192 -347 283 -618 270 -803 -10 -141 -50 -235 -206 -485 -169 -270 -203 -345 -217 -483 -15 -148 48 -348 210 -668 l34 -66 -97 -43 c-157 -70 -445 -218 -539 -277 -312 -196 -491 -394 -613 -680 -71 -165 -119 -405 -119 -586 1 -174 48 -387 132 -592 27 -66 52 -129 54 -140 4 -17 -14 -31 -108 -84 -373 -212 -584 -382 -736 -597 -130 -182 -242 -452 -298 -719 -25 -116 -28 -151 -28 -320 0 -172 3 -199 26 -285 14 -52 44 -137 66 -188 40 -92 40 -93 19 -102 -128 -49 -475 -214 -587 -278 -432 -249 -663 -553 -751 -989 -27 -134 -38 -468 -20 -620 47 -409 199 -768 408 -964 179 -167 389 -257 715 -304 438 -65 464 -67 648 -68 253 -2 320 13 697 154 496 185 974 262 1483 238 332 -16 548 -44 1325 -173 723 -120 1046 -163 1425 -187 209 -13 871 -7 1090 11 262 21 648 62 910 96 146 19 337 44 425 55 173 21 319 54 424 96 197 77 463 244 700 439 225 184 346 401 397 709 22 138 14 427 -16 570 -63 299 -177 524 -354 701 -179 180 -426 305 -803 409 -57 16 -103 32 -103 36 0 3 21 65 46 135 58 165 109 362 124 487 17 135 8 373 -20 502 -68 322 -240 680 -435 908 -160 187 -393 317 -692 387 -29 7 -53 15 -53 19 0 5 9 34 21 67 69 199 94 412 66 559 -42 222 -178 449 -391 653 -229 217 -457 327 -841 401 -75 15 -90 21 -88 36 30 329 24 537 -22 752 -76 360 -292 778 -597 1158 -261 324 -552 570 -995 842 -61 37 -151 94 -199 127 -109 74 -249 140 -350 167 -104 27 -290 34 -554 21 -300 -16 -468 3 -751 84 -130 37 -159 40 -177 22z" />
</g>
</svg>
)
}

export default ShitSvg;