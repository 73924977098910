import React from "react"
import Header from './components/header/header';
import MainContent from './components/MainContent/MainContent'
export default function Home() {
  return  (
    <>
  <Header></Header>
  <MainContent /> 
  </>
  );
}       
