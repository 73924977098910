import React from 'react';
import './usButton.css';
import soundFileEng from '../../../../static/us-1.m4a';
import soundFileAr from '../../../../static/us-ar.m4a';
import useSound from 'use-sound'



export default function UsButton(props) {
    const [play] = useSound(Math.random() <= 0.5 ? soundFileAr : soundFileEng);

   return( <button onClick={play}> 
        {props.msg}
    </button>
    )
}